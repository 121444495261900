var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  width: "5%",
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "发售时间(UTC)",
  dataIndex: "releasetime",
  key: "releasetime",
  width: "10%",
  scopedSlots: {
    customRender: "releasetime"
  }
}, {
  title: "发售用户昵称",
  dataIndex: "usernickname",
  key: "usernickname",
  width: "10%",
  scopedSlots: {
    customRender: "usernickname"
  }
}, {
  title: "发售用户ID",
  dataIndex: "useruid",
  key: "useruid",
  width: "10%",
  scopedSlots: {
    customRender: "useruid"
  }
}, {
  title: "是否平台精选",
  dataIndex: "isplatformselection",
  key: "isplatformselection",
  width: "10%",
  scopedSlots: {
    customRender: "isplatformselection"
  }
},
// {
//   title: "是否有发售白名单",
//   dataIndex: "iswhitelistbook",
//   key: "iswhitelistbook",
//   width: "10%",
//   scopedSlots: {
//     customRender: "iswhitelistbook",
//   },
// },
{
  title: "状态",
  dataIndex: "auditstatus",
  key: "auditstatus",
  width: "15%",
  scopedSlots: {
    customRender: "auditstatus"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "20%",
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };