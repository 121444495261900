var searchKeys = [{
  key: "usernickname",
  label: "发售用户昵称",
  placeholder: "中文名称/英文名称",
  required: false,
  rules: [],
  input: true
}, {
  key: "useruid",
  label: "发售用户ID",
  placeholder: "用户ID",
  required: false,
  rules: [],
  input: true
}, {
  key: "dates",
  label: "发售时间(UTC)",
  format: "YYYY-MM-DD HH:mm:ss",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  required: false,
  rules: [{
    required: false
  }]
},
// {
//     key: "ip",
//     label: "IP地址",
//     placeholder: "",
//     required: false,
//     rules: [],
//     input: true
// },
{
  key: "isplatformselection",
  label: "发售任务是否平台精选",
  placeholder: "",
  required: false,
  rules: [],
  group: true,
  initialValue: "0"
}, {
  key: "iswhitelistbook",
  label: "发售白名单",
  placeholder: "",
  required: false,
  rules: [],
  group: true,
  initialValue: "1"
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };